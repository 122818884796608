import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import DropDown from '../dropDown';
import languages from '../../data/languages';

import styles from './languageMenu.module.scss';

const LanguageMenu = ({ intl }) => {
  const currentLanguage = intl.locale;

  const title = {
    messageId: `locale.${currentLanguage}`,
  };

  const otherLanguages = languages.langs.filter(
    (lang) => lang !== currentLanguage
  );

  const options = otherLanguages.map((lang) => {
    if (lang !== currentLanguage) {
      return {
        url: lang,
        messageId: `locale.${lang}`,
      };
    }
  });

  return (
    <DropDown
      containerStyles={styles.container}
      contentStyles={styles.content}
      options={options}
      title={title}
    />
  );
};

LanguageMenu.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LanguageMenu);
