const menuPaths = {
  happinessCorner: {
    es: 'rincon-de-felicidad',
    en: 'corner-of-happiness',
    it: 'angolo-di-felicita',
    pt: 'cantinho-da-felicidade',
  },

  messages: {
    es: 'mensajes-de-LJ',
    en: 'messages-from-LJ',
    it: 'messaggi-di-LJ',
    pt: 'mensagens-de-LJ',
  },

  messageLJ: {
    es: 'escribe-a-LJ',
    en: 'message-LJ',
    it: 'scrivi-a-LJ',
    pt: 'escreva-para-LJ',
  },

  legal: {
    es: 'nota-legal',
    en: 'legal-notice',
    it: 'informazioni-legali',
    pt: 'representante-legal',
  },
};

module.exports = {
  menuPaths,
};
