import en from './messages/en';
import it from './messages/it';
import es from './messages/es';
import pt from './messages/pt';

const config = {
  en,
  es,
  it,
  pt,
};

export default config;
