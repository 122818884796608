import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './footer.module.scss';

const DotsDesign = ({ alignEnd }) => {
  const containerStyle = classNames(styles.dotsContainer, {
    [styles.alignEnd]: alignEnd,
  });

  return (
    <div className={containerStyle}>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
    </div>
  );
};

DotsDesign.propTypes = {
  alignEnd: PropTypes.bool.isRequired,
};

export default DotsDesign;
