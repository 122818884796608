const messages = {
  'aboutLJ.founderOfMSF':
    'La Jardinera es la fundadora de la organización internacional Manos Sin Fronteras y precursora de la técnica de Estimulación Neural.',
  'aboutLJ.founder.first': 'Fundadora ',
  'aboutLJ.founder.second': 'de la organización internacional',
  'aboutLJ.founder.third': 'Manos Sin Fronteras',
  'aboutLJ.founder.fourth': 'y precursora de la técnica de',
  'aboutLJ.founder.fifth': 'Estimulación Neural.',
  'aboutLJ.5Minutes':
    'Es el motor de la campaña “5 Minutos, Yo Medito”, que ha llevado la meditación a miles de personas.',
  'aboutLJ.lifeWork':
    'Ha dedicado su vida a mejorar el bienestar de las personas y como parte de esta misión envía mensajes con historias, lecciones de vida, con consejos, con amor que nos motivan a vivir una vida más ligera...',
  'aboutLJ.lifework.first':
    'Ha dedicado su vida a mejorar el bienestar de las personas',
  'aboutLJ.lifework.second':
    'y como parte de esta misión envía mensajes con historias, lecciones de vida, con consejos, con amor que nos motivan a vivir una vida más ligera...',
  'aboutLJ.messages':
    ' Nos motiva a vivir una vida guiada por nuestro corazón, siempre conscientes de los que nos rodean y del potencial que tenemos para ayudar.',

  'altText.home.LJ': 'Foto de La Jardinera en Burma',
  'altText.home.msfLogo': 'Logo de Manos Sin Fronteras',
  'altText.home.institutoLogo':
    'Logo del Instituto Internacional de Estimulación Neural',
  'altText.home.euMeditoLogo': 'Logo de la campaña: 5 Minutos Yo Medito',

  'contact.name': 'Nombre',
  'contact.category': 'Categoría',
  'contact.email': 'Correo',
  'contact.message': 'Mensaje',
  'contact.number': 'Número',
  'contact.submit': 'Enviar',
  'contact.worldMap': 'MSF en el Mundo',

  'contact.category1': 'reflexion',
  'contact.label.category1': 'reflexion',
  'contact.category2': 'pregunta',
  'contact.label.category2': 'pregunta',
  'contact.category3': 'pedidos',
  'contact.label.category3': 'pedido',
  'contact.select': 'Elegir...',

  'footer.manos.label': 'MSF_int',
  'footer.manos.altText': 'Mundo Monocromo',
  'footer.yoMedito.label': 'Yo Medito',
  'footer.yoMedito.altText': 'Globo monocromo',
  'footer.laJardinera.label': 'La Jardinera',
  'footer.laJardinera.altText': 'Flor monocromática',
  'footer.copyright': '© 2022 Manos Sin Fronteras',

  'home.content.title': 'La Jardinera',
  'home.title.phrase': 'En la sencillez está la sabiduría',

  'home.buttons.phraseCorner': 'Escucha',
  'home.buttons.corner': 'El rincón de la felicidad de LJ',

  'home.buttons.phraseMessages': 'Escucha y lee',
  'home.buttons.messages': 'Mensajes de La Jardinera',

  'home.discover.title': 'Descubre más sobre:',
  'home.discoverMore.msf': 'Estimulación Neural',
  'home.discoverMore.msf2': 'y Manos Sin Fronteras',
  'home.discoverMore.instituto': 'Instituto de Estimulación Neural',
  'home.discoverMore.euMedito': '5 Minutos,',
  'home.discoverMore.euMedito2': 'Yo Medito',

  'home.writeToLJ.text':
    '¿Tienes preguntas o quieres compartir tus reflexiones con La Jardinera?',

  'messagesFromLJ.title': 'Mensajes',
  'messagesFromLJ.subTitle': 'de La Jardinera',

  'legal.address.label': 'Dirección Registrada',
  'legal.address.line1': 'Manos Sin Fronteras Internacionales',
  'legal.address.line2': 'C/ Ribargorza no. 18',
  'legal.address.line3': 'Entresuelo B de Zaragoza',
  'legal.address.line4': '50003 (Zaragoza)',
  'legal.contact.email': 'Correo Electrónico: ',
  'legal.contact.phone': 'Teléfono: +34 658 77 46 78',
  'legal.contact.website': 'Sitio Web: ',
  'legal.representative.label': 'Representante Legal',
  'legal.representative.name': 'Lucía Peña',
  'legal.representative.with': 'Registro: Diputación General de Aragón',
  'legal.representative.number': 'Número de Registro: 01-Z-2070-2007',
  'legal.representative.code': 'Código de Identificación Definitivo: G99197394',
  'legal.disclaimer.label': 'Declaración Legal',
  'legal.disclaimer.text':
    'El contenido de este sitio web está basado en normas de España, salvo indicación expresa en contrario. Las opiniones y criterios publicados en los artículos representan aquellos de los autores y no el de El Blog de Manos Sin Fronteras Internacionales y sus creadores. Si existe alguna sugerencia o reclamo respecto lo aquí publicado, agradecemos dirigirse a la sección de contacto para atender su comunicación. Todos los artículos aquí publicados tienen el consentimiento expreso de su autor.',

  'locale.en': 'EN',
  'locale.es': 'ES',
  'locale.it': 'IT',
  'locale.pt': 'PT',

  'meta.description.messages':
    'Lee los mensajes que La Jardinera comparte con el mundo, detrás de cada uno hay una oportunidad de crecimiento y felicidad.',
  'meta.description.messageLJ':
    '¿Quieres compartir tus experiencias y conocimientos? ¿Tienes comentarios o preguntas sobre el sitio o los artículos que publicamos? Escribenos un mensaje y te contestaremos enseguida.',
  'meta.description.home':
    '¡Bienvenidos al blog de La Jardinera! Te invitamos a leer los mensajes que La Jardinera nos envía con muchísimo amor; historias, acontecimientos y experiencias para reflexionar y aprender.',
  'meta.description.happinessCorner':
    'Entra en el rincón de la felicidad de La Jardinera. Escucha mensajes cortos con consejos para encontrar la felicidad.',

  'menu.messages': 'Mensajes',
  'menu.happinessCorner': 'Rincón de la Felicidad',
  'menu.messageLJ': 'Escribe a LJ',
  'menu.home': 'Inicio',
  'menu.legal': 'Nota Legal',

  'sidePanel.estimulacionNeural.altText': 'Estimulación Neural',
  'sidePanel.estimulacionNeural.text':
    'Una técnica para tu bienestar físico, mental y emocional.',
  'sidePanel.estimulacionNeural.title': 'Estimulación Neural',
  'sidePanel.laJardinera.text':
    'Precursora de la Estimulación Neural, ha dedicado su vida a ayudar al planeta.',
  'sidePanel.yoMedito.altText': 'Globo - Yo Medito',
  'sidePanel.yoMedito.text': 'Cuando tú estás en paz, el mundo está en paz.',
  'sidePanel.yoMedito.title': 'Yo Medito',

  'template.comments': 'Escribe tus comentarios',
};

export default messages;
