import React from 'react';
import { Link } from 'gatsby';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { menuPaths } from '../constants/menuPaths';

import styles from '../styles/components/menuWide.module.scss';

const MenuWide = ({ intl }) => {
  const language = intl.locale;

  const happinessCornerPath = `/${language}/${menuPaths.happinessCorner[language]}`;
  const messagesPath = `/${language}/${menuPaths.messages[language]}`;
  const messageLJPath = `/${language}/${menuPaths.messageLJ[language]}`;

  return (
    <nav className={styles.container}>
      <ul className={styles.menuWide}>
        <li>
          <Link to={messagesPath} activeClassName={styles.activeLink}>
            <FormattedMessage id="menu.messages" />
          </Link>
        </li>
        <li>
          <Link to={happinessCornerPath} activeClassName={styles.activeLink}>
            <FormattedMessage id="menu.happinessCorner" />
          </Link>
        </li>
        <li>
          <Link to={messageLJPath} activeClassName={styles.activeLink}>
            <FormattedMessage id="menu.messageLJ" />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

MenuWide.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(MenuWide);
