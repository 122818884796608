import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { injectIntl, intlShape } from 'react-intl';

import LanguageMenu from '../languageMenu/languageMenu';
import MenuWide from '../menuWide';
import MenuNarrow from '../menuNarrow/menuNarrow';

import { ReactComponent as LJIcon } from '../../images/svgs/lj-icon.svg';
import styles from './header.module.scss';

const Header = ({ intl }) => {
  const [isFullHeaderOpen, setIsFullHeaderOpen] = useState(true);

  useEffect(() => {
    const handleHeaderChange = () => {
      if (window.scrollY > 5) {
        setIsFullHeaderOpen(false);
      } else {
        setIsFullHeaderOpen(true);
      }
    };

    window.addEventListener('scroll', handleHeaderChange);

    return () => window.removeEventListener('scroll', handleHeaderChange);
  }, []);

  let currentLanguage = '/';

  if (intl.locale != null) {
    currentLanguage = `/${intl.locale}/`;
  }

  return (
    <header className={styles.container}>
      <div className={styles.headerSection}>
        <div className={styles.menuContainer}>
          <div className={styles.logoContainer}>
            <Link to={currentLanguage}>
              <LJIcon />
            </Link>
          </div>
          <MenuWide />
          <MenuNarrow />
          {isFullHeaderOpen && (
            <div className={styles.languageContainer}>
              <LanguageMenu />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Header);
