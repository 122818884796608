import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'intl';
import { IntlProvider, addLocaleData } from 'react-intl';
import { getCurrentLangKey } from 'ptz-i18n';
import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';
import es from 'react-intl/locale-data/es';
import 'intl/locale-data/jsonp/es';
import it from 'react-intl/locale-data/it';
import 'intl/locale-data/jsonp/it';
import pt from 'react-intl/locale-data/pt';
import 'intl/locale-data/jsonp/pt';

import Header from './header/header';
import Footer from '../components/footer/footer';
import languages from '../data/languages';
import languagesConfig from '../data/config';
import SocialMedia from '../components/socialMedia/socialMedia';

import '../styles/main.scss';
import styles from '../styles/layouts/index.module.scss';
import favicon from '../images/icons/favicon.ico';

addLocaleData([...en, ...es, ...it, ...pt]);

const Layout = ({ children, location }) => {
  const url = location.pathname;
  const { langs, defaultLangKey } = languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const i18nMessages = languagesConfig[langKey];

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <div className={styles.container}>
        <Helmet defaultTitle="La Jardinera" titleTemplate="%s | La Jardinera">
          <html lang={langKey} />
          <meta property="og:locale" content={langKey} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="La Jardinera" />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="icon" type="image/x-icon" href={favicon} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#fbbe00" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-config" content="/browserconfig.xml" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <Header />
        {children}
        <SocialMedia />
        <Footer />
      </div>
    </IntlProvider>
  );
};

Layout.defaultProps = {
  location: {
    pathname: '',
  },
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default Layout;
