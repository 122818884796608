const messages = {
  'aboutLJ.founderOfMSF':
    "La Jardinera è la fondatrice dell'organizzazione internazionale Manos Sin Fronteras e la precorritrice della tecnica di Stimolazione Neurale.",
  'aboutLJ.founder.first': 'Fondatrice',
  'aboutLJ.founder.second': "dell'organizzazione internazionale",
  'aboutLJ.founder.third': 'Manos Sin Fronteras',
  'aboutLJ.founder.fourth': 'e la precorritrice della tecnica di',
  'aboutLJ.founder.fifth': 'Stimolazione Neurale.',
  'aboutLJ.5Minutes':
    'È la forza trainante della campagna “5 Minutes, I Meditate”, che ha portato la meditazione a migliaia di persone.',
  'aboutLJ.lifeWork':
    'Ha dedicato la sua vita a migliorare il benessere delle persone e come parte di questa missione invia messaggi con storie, lezioni di vita, con consigli, con amore che ci motivano a vivere una vita più leggera...',
  'aboutLJ.lifework.first':
    'Ha dedicado su vida a mejorar el bienestar de las personas',
  'aboutLJ.lifework.second':
    'y como parte de esta misión envía mensajes con historias, lecciones de vida, con consejos, con amor que nos motivan a vivir una vida más ligera...',
  'aboutLJ.messages':
    'Una vita guidati dal nostro cuore, sempre consapevoli di chi ci circonda e del potenziale che abbiamo per aiutare.',

  'altText.home.LJ': 'Foto di La Jardinera in Birmania',
  'altText.home.msfLogo': 'Logo di Manos Sin Fronteras',
  'altText.home.institutoLogo':
    "Logo dell'Istituto Internazionale di Stimolazione Neurale",
  'altText.home.euMeditoLogo': 'Logo della campagna: 5 minuti di meditazione',

  'contact.name': 'Nome',
  'contact.category': 'Categoria',
  'contact.email': 'Email',
  'contact.message': 'Messagio',
  'contact.number': 'Numero',

  'contact.submit': 'Inviare',
  'contact.worldMap': 'MSF Nel Mondo',

  'contact.category1': 'riflessioni',
  'contact.label.category1': 'riflessioni',
  'contact.category2': 'domande',
  'contact.label.category2': 'domande',
  'contact.category3': 'richiedere qualcosa',
  'contact.label.category3': 'richiedere qualcosa',
  'contact.select': 'Scegliere...',

  'footer.manos.label': 'MSF_int',
  'footer.manos.altText': 'Manos Sin Fronteras',
  'footer.yoMedito.label': 'Io Medito',
  'footer.yoMedito.altText': 'Palloncino monocromatico',
  'footer.laJardinera.label': 'La Jardinera',
  'footer.laJardinera.altText': 'Fiore monocromatico',
  'footer.copyright': '© 2022 Manos Sin Fronteras',

  'home.content.title': 'La Jardinera',
  'home.title.phrase': 'La saggezza è nella semplicità',

  'home.buttons.phraseCorner': 'Ascolta',
  'home.buttons.corner': "L'angolo della felicità della Jardinera",
  'home.buttons.phraseMessages': 'Leggi e ascolta',
  'home.buttons.messages': 'Messaggi della Jardinera',

  'home.discover.title': 'Scopri di più su:',
  'home.discoverMore.msf': 'Estimulación Neural',
  'home.discoverMore.msf2': 'y Manos Sin Fronteras',
  'home.discoverMore.instituto': 'Instituto de Estimulación Neural',
  'home.discoverMore.euMedito': '5 Minuti,',
  'home.discoverMore.euMedito2': 'Io Medito',

  'home.writeToLJ.text':
    'Hai domande o vuoi condividere le tue riflessioni con La Jardinera?',

  'messagesFromLJ.title': 'Messaggi',
  'messagesFromLJ.subTitle': 'della Jardinera',

  'legal.address.label': 'Sede Legale',
  'legal.address.line1': 'Manos Sin Fronteras Internacionales',
  'legal.address.line2': 'C/ Ribargorza no. 18',
  'legal.address.line3': 'Entresuelo B de Zaragoza',
  'legal.address.line4': '50003 (Zaragoza)',
  'legal.contact.email': 'Indirizzo di posta elettronica: ',
  'legal.contact.phone': 'Telefono: +34 658 77 46 78',
  'legal.contact.website': 'Sito Web: ',
  'legal.representative.label': 'Rappresentante Legale',
  'legal.representative.name': 'Lucía Peña',
  'legal.representative.with': 'Registro: Diputación General de Aragón',
  'legal.representative.number': 'Numero di Registro: 01-Z-2070-2007',
  'legal.representative.code':
    'Codice di identificazione Fiscale – P.IVA: G99197394',
  'legal.disclaimer.label': 'Dichiarazione Legale',
  'legal.disclaimer.text':
    'Il contenuto di questo sito web è basato sulle leggi della Spagna, salvo indicazione espressa del contrario. Le opinioni e criteri pubblicati negli articoli rappresentano quelli dei rispettivi autori, e non del Blog di Manos Sin Fronteras Internacionales ed i suoi creatori. Se si vuole fare qualche suggerimento o porre un reclamo in relazione a quanto qui pubblicato, vi preghiamo di scriverci nella sezione Contatti. Abbiamo pubblicato gli articoli previ consenso espresso dei rispettivi autori.',

  'locale.en': 'EN',
  'locale.es': 'ES',
  'locale.it': 'IT',
  'locale.pt': 'PT',

  'meta.description.messages':
    "Leggi i messaggi che La Jardinera condivide con il mondo, dietro ognuno si nasconde un'opportunità di crescita e felicità.",
  'meta.description.messageLJ':
    'Vuoi condividere ciò che sai o la tua esperienza? Vuoi commentare gli articoli che pubblichiamo? Scrivici un messaggio e ti risponderemo subito.',
  'meta.description.home':
    'Benvenuto nel blog La Jardinera! Vi invitiamo a leggere i messaggi che La Jardinera ci invia con tanto affetto; storie, eventi ed esperienze per riflettere e apprendere.',
  'meta.description.happinessCorner':
    "Entra nell'angolo della Felicità di La Jardinera. Ascolta brevi messaggi con consigli su come essere felici.",

  'menu.messages': 'Messaggi',
  'menu.happinessCorner': 'Angolo di Felicità',
  'menu.messageLJ': 'Scrivi a LJ',
  'menu.home': 'Home',
  'menu.legal': 'Informazioni Legali',

  'sidePanel.estimulacionNeural.altText': 'Stimolazione Neurale',
  'sidePanel.estimulacionNeural.text':
    'Una tecnica per il tuo benessere físico, mentale ed emozionale. ',
  'sidePanel.estimulacionNeural.title': 'Stimolazione Neurale',
  'sidePanel.laJardinera.text':
    'Precorritrice della Stimolazione Neurale, ha dedicato la sua vita ad aiutare il Pianeta.',
  'sidePanel.yoMedito.altText': 'palloncino - Io Medito ',
  'sidePanel.yoMedito.text': 'Quando tu sei in pace, il mondo è in pace.',
  'sidePanel.yoMedito.title': 'Io Medito',

  'template.comments': 'Lascia un commento',
};

export default messages;
