import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import styles from '../styles/components/dropDown.module.scss';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggleMenu = this._toggleMenu.bind(this);
    this.navigate = this._navigate.bind(this);
  }

  _toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  _navigate(path) {
    navigate(path);
    this.setState({ isOpen: false });
  }

  handleClickOutside() {
    if (this.state.isOpen) {
      this.toggleMenu();
    }
  }

  render() {
    const optionButtons = this.props.options.map((option) => {
      const buttonClass = classNames(
        { [this.props.nestedStyle]: option.isNested },
        [option.style]
      );
      return (
        <button
          onClick={() => this.navigate(`/${option.url}/`)}
          key={option.messageId}
          className={buttonClass}
          disabled={!option.url}
        >
          <FormattedMessage id={option.messageId} />
        </button>
      );
    });

    const titleButton = (
      <button onClick={this.toggleMenu} aria-label="Open Dropdown">
        {this.props.title.messageId && (
          <FormattedMessage id={this.props.title.messageId} />
        )}
        {this.props.title.icon}
      </button>
    );

    const containerStyles = classNames(
      this.props.containerStyles,
      styles.container
    );
    const contentStyles = classNames(this.props.contentStyles, styles.content);

    return (
      <div className={containerStyles}>
        {titleButton}
        {this.state.isOpen && (
          <div className={contentStyles}>
            <div>{optionButtons}</div>
          </div>
        )}
      </div>
    );
  }
}

DropDown.propTypes = {
  containerStyles: PropTypes.string.isRequired,
  contentStyles: PropTypes.string.isRequired,
  nestedStyle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      messageId: PropTypes.string.isRequired,
      isNested: PropTypes.bool,
      style: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.shape({
    icon: PropTypes.element,
    messageId: PropTypes.string,
  }).isRequired,
};

export default onClickOutside(DropDown);
