import React from 'react';
import { Link } from 'gatsby';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { menuPaths } from '../../constants/menuPaths';

import euMeditoLogo from '../../images/euMeditoLogo.png';
import institutoLogo from '../../images/institutoLogo.png';
import msfLogo from '../../images/msfLogo.png';
import DotsDesign from './dotsDesign';

import styles from './footer.module.scss';

const Footer = ({ intl }) => {
  const language = intl.locale;

  const legalPath = `/${language}/${menuPaths.legal[language]}`;

  return (
    <footer className={styles.container}>
      <DotsDesign />
      <div className={styles.discoverContainer}>
        <a href="https://www.msfint.com" className={styles.logoContainer}>
          <img
            src={msfLogo}
            alt={intl.formatMessage({ id: 'altText.home.msfLogo' })}
          />
          <FormattedMessage id="home.discoverMore.msf" />
          <FormattedMessage id="home.discoverMore.msf2" />
        </a>
        <a href="https://institutoen.org" className={styles.logoContainer}>
          <img
            src={institutoLogo}
            alt={intl.formatMessage({ id: 'altText.home.institutoLogo' })}
          />
          <FormattedMessage id="home.discoverMore.instituto" />
        </a>
        <a href="https://eumedito.org" className={styles.logoContainer}>
          <img
            src={euMeditoLogo}
            alt={intl.formatMessage({ id: 'altText.home.euMeditoLogo' })}
          />
          <FormattedMessage id="home.discoverMore.euMedito" />
          <FormattedMessage id="home.discoverMore.euMedito2" />
        </a>
      </div>
      <div className={styles.legal}>
        <Link to={legalPath}>
          <FormattedMessage id="menu.legal" />
        </Link>
        <FormattedMessage id="footer.copyright" />
      </div>
      <DotsDesign alignEnd />
    </footer>
  );
};

Footer.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
