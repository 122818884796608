import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import DropDown from '../dropDown';

import { ReactComponent as Hamburger } from '../../images/svgs/bars-solid.svg';
import styles from './menuNarrow.module.scss';
import { menuPaths } from '../../constants/menuPaths';

const HeaderMenuNarrow = ({ intl, isOffset }) => {
  const language = intl.locale;

  const title = {
    icon: <Hamburger />,
  };

  const options = [];

  const messagesPath = `${language}/${menuPaths.messages[language]}`;
  const messageLJPath = `${language}/${menuPaths.messageLJ[language]}`;
  const cornerOfHappiness = `${language}/${menuPaths.happinessCorner[language]}`;

  options.push({
    url: language,
    messageId: 'menu.home',
  });

  options.push({
    url: messagesPath,
    messageId: 'menu.messages',
  });

  options.push({
    url: cornerOfHappiness,
    messageId: 'menu.happinessCorner',
  });

  options.push({
    url: messageLJPath,
    messageId: 'menu.messageLJ',
  });

  const contentClass = classNames(styles.content, {
    [styles.content_offset]: isOffset,
  });

  return (
    <DropDown
      containerStyles={styles.container}
      contentStyles={contentClass}
      nestedStyle={styles.nested}
      options={options}
      title={title}
    />
  );
};

HeaderMenuNarrow.propTypes = {
  intl: intlShape.isRequired,
  isOffset: PropTypes.bool.isRequired,
};

export default injectIntl(HeaderMenuNarrow);
